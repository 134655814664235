<script>
import api from '@/command/api'
import { getAction, postAction } from '../../../command/netTool'
import DetailFormGroup from '../../../components/DetailFormGroup'
import CardIconLabelLocal from '../../../components/CardIconLabelLocal'
export default {
  name: 'farmersBrigadeMap',
  data() {
    return {
      ...api.command.getState(),
      otherTags: [],
      travelMapDistanceList: [],
      travelMapLabelList: [],
      detail: {}
    }
  },
  mounted() {
    this.getInitData()
  },
  methods: {
    getInitData() {
      api.command.getList
        .call(this, {
          url: '/config/farmConfigTravelMap/getTravelMap'
        })
        .then(result => {
          let dataSource = result.data
          this.travelMapDistanceList = dataSource.travelMapDistanceList
          this.travelMapLabelList = dataSource.travelMapLabelList
          dataSource.travelMapDistanceList.forEach((element, i) => {
            this.detail['showDistance' + i] = element.showDistance
          })
          this.otherTags = dataSource.travelMapLabelList[0].farmLabelDataVOList.map(e => {
            return {
              ...e,
              publicLabel: '0'
            }
          })
        })
    },
    getForm1() {
      let arr = this.travelMapDistanceList.map((e, i) => {
        return {
          name: `线路距离${i + 1}`,
          type: 'input',
          cols: 8,
          key: 'showDistance' + i,
          props: {
            addonAfter: 'KM'
          }
        }
      })

      return {
        title: '线路生成范围',
        type: 'cardForm',
        data: arr
      }
    },
    getLabelForm() {
      return [
        {
          name: '标签名称',
          type: 'input',
          key: 'labelName'
        },
        {
          name: '标签类型',
          type: 'radioButton',
          key: 'publicLabel',
          typeData: [
            {
              name: '店铺私有',
              value: '2'
            },
            {
              name: '公用标签库',
              value: '1'
            }
          ]
        }
      ].filter(e => e)
    },
    getForm2() {
      return {
        type: 'row',
        children: [
          <CardIconLabelLocal
            data={this.getLabelForm()}
            isAddBtn={false}
            title={'其他（高优先级）'}
            pubBool={false}
            cols={24}
            style={{ marginBottom: '10px', minHeight: '200px' }}
            icons={this.otherTags}
          />
        ]
      }
    },
    onSubmit() {
      let travelMapDistanceList = this.travelMapDistanceList.map((e, i) => {
        return {
          ...e,
          showDistance: this.detail['showDistance' + i]
        }
      })
      this.travelMapLabelList[0].farmLabelDataVOList = this.otherTags

      postAction('/config/farmConfigTravelMap/update', {
        travelMapDistanceList: travelMapDistanceList,
        travelMapLabelList: this.travelMapLabelList
      }).then(result => {
        if (result.code == 200) {
          this.$message.success(result.data)
          this.getInitData()
        } else {
          this.$message.error(result.data)
        }
      })
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          sumbit: true,
          onClick: data => {
            let travelMapDistanceList = this.travelMapDistanceList.map((e, i) => {
              return {
                ...e,
                showDistance: this.detail['showDistance' + i]
              }
            })
            this.travelMapLabelList[0].farmLabelDataVOList = this.otherTags

            postAction('/config/farmConfigTravelMap/update', {
              travelMapDistanceList: travelMapDistanceList,
              travelMapLabelList: this.travelMapLabelList
            }).then(result => {
              if (result.code == 200) {
                this.$message.success(result.data)
                this.getInitData()
              } else {
                this.$message.error(result.data)
              }
            })
          }
        }
      ]
      const right = [
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPost.call(this, {
              url: `/config/farmConfigInvest/delete?id=${this.detail.id}`
            })
            setTimeout(() => {
              this.$router.back()
            }, 500)
          }
        }
      ]
      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup form={this.detail} foot={this.getFoot()} data={[this.getForm1(), this.getForm2()]} />
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
